import React from "react";
import {Link} from 'react-router-dom';

var que = localStorage.getItem('us');
if(que!= null){
  que = que.replace(/"/g,' ');
}



export default function Aside() {
  return (
    <aside id="asi" className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <a href="#" className="brand-link">
        <img
          src="../dist/img/AdminLTELogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">App. San Vicente</span>
      </a>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
          <i src="#" className=" fas c fa-user-circle" alt="User Image"></i>
          </div>
          <div className="info">
            <a href="#" className="d-block">
              
              {que}
            </a>
          </div>
          {/* <div className="info">
          <a data-widget="pushmenu" class="fa-solid fa-x"></a>
          </div> */}
        </div>
        {/* SidebarSearch Form */}
        {/* <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div> */}
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
           // data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
           {/* <li id ="menu_li" className="nav-item">
           <button href="#" className="nav-link v">
                <i className="nav-icon fas fa-copy" />
                <p>
                  ChileInmune
                  <i className="fas fa-angle-left right" />
                  <span className="badge badge-info right">6</span>
                </p>
              </button>
              <ul id="menu" className="nav nav-treeview">
              <li className="nav-item">
                  <a href="/ver" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Servicios</p>
                  </a>
                </li>
                
              </ul>
            </li> */}
            {/* {empieza el menu} */}

{/* primer boxx */}
            <li id="boxx"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-edit" />
    <p>
      Ingresar
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_1" className="nav nav-treeview">


    <li className="nav-item">
      <a href="/contenido" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Ingresar Guia/Vale </p>
      </a>
    </li>

    

    
  </ul>
</li>

{/* segundo boxx */}
<li id="boxxc"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-edit" />
    <p>
      Crear
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_c" className="nav nav-treeview">

  <li className="nav-item">
      <a href="/distancias" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Ingresar Distancia </p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/materiales" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear Materiales</p>
      </a>
    </li>


    <li className="nav-item">
      <a href="/conductores" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear Conductor</p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/patentes" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear Patente</p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/ubicaciones" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear Ubicacion</p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/destinos" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear Destino</p>
      </a>
    </li>
    
    <li className="nav-item">
      <a href="/subi" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Crear SubDestino</p>
      </a>
    </li>

    
  </ul>
</li>

{/* Menu Petroleo */}
<li id="boxx2"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-gas-pump" />
    <p>
      Petroleo
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_2" className="nav nav-treeview">


    <li className="nav-item">
      <a href="/petroleo" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Asignar Petroleo Camión </p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/detalleP" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Detalle Petroleo</p>
      </a>
    </li>
    
    

    

    
  </ul>
</li>

{/* Menu Gastos*/}
<li id="boxx3"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-circle-dollar-to-slot" />
    <p>
      Gastos
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_3" className="nav nav-treeview">


    <li className="nav-item">
      <a href="/gastos" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Ingresar Gastos </p>
      </a>
    </li>
    

    

    
  </ul>
</li>

{/* Menu Produccion*/}
<li id="boxx4"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-truck" />
    <p>
      Producción
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_4" className="nav nav-treeview">


    <li className="nav-item">
      <a href="/produccion" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Conductores </p>
      </a>
    </li>

    
    

    

    
  </ul>
</li>

{/* Menu Estadistica*/}
<li id="boxx5"  className="nav-item boxx">
  <a href="#" className="nav-link">
    <i className="nav-icon fas fa-chart-column" />
    <p>
      Estadisticas
      <i className="right fas fa-angle-left" />
    </p>
  </a>
  <ul id="boxx_5" className="nav nav-treeview">


    <li className="nav-item">
      <a href="/estadisica_1" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Costos por Patente </p>
      </a>
    </li>

    <li className="nav-item">
      <a href="/inicio" className="nav-link">
        <i className="far fa-circle nav-icon" />
        <p>Inicio</p>
      </a>
    </li>

    

    

    
  </ul>
</li>


          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
