import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'



export default function Distancias() {

  var que = localStorage.getItem('us');
if(que!= null){
  que = que.replace(/"/g,' ');
}
var sube =[];
// Para enviar a otras paginas
const history = useHistory();

// Variables de los combobox
const [ubi, setUbi] = useState([]); // ubicaciones
const [des, setDes] = useState([]); // destinos
//combobox Subdestinos
const [subde, setSubde] = useState([]); // destinos

// estado editable
const [edi, setEdir] = useState(true);

 //Editables
 const [edDis,setEdDis] = useState({});

const [dl,setDl] = useState({}); //Eliminar

const [dista,setDista] = useState([]); //Tabla Editable


// Inputs del formulario
const [inputs, setInputs] = useState({});




// Modal de éxito
const swal_borrar = (event1) => {
  withReactContent(Swal).fire({
    title: "Eliminar fila",
    text: "¿Está Seguro de querer eliminar esta fila?",
    icon: "warning",
    confirmButtonColor: 'red',
    confirmButtonText: 'Si',
    showCancelButton: true,
    cancelButtonText: "Cancelar"
    
  });
  $('.swal2-confirm').click(function(){
    delel(event1);
});
};

// Modal de éxito
const swal_bien = () => {
  withReactContent(Swal).fire({
    title: "Bien",
    text: "Ingresado Con Éxito",
    icon: "success",
    
  });
};

// Se llaman las Funciones
useEffect(() => {
  //Carga todo
  getAll();
}, []);

// Registra las entradas de los inputs
const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs((values) => ({ ...values, [name]: value }));
  setInputs((values) => ({ ...values, ["digitador"]: que2 }));
  setInputs((values) => ({ ...values, ["codigo"]: "ingDis" }));
};
// Envia el formulario
const handleSubmit = (event) => {
  event.preventDefault();
  axios
    .post(rialURL + "sanvi/api/user/save", inputs)
    .then(function (response) {
      console.log(response.data);
      swal_bien();
      document.getElementById("ubicacion").value = "0";
      document.getElementById("destino").value = "0";
      document.getElementById("distancia").value = "";
      document.getElementById("valor").value = "";
      
      setInputs(null); // limpia lo que se envia 
      //history.push('/Ver');
    });

  //console.log(inputs);
};

// Funcion get para todos los comboBox
function getAll() {

 // Get Ubicaciones
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getUbi")
      .then(function (response) {
       // console.log(response.data);
        setUbi(response.data);
      });

       // Get Destinos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getDes")
    .then(function (response) {
      //console.log(response.data);
      setDes(response.data);
    });

    // Get Distancias
    axios.get(rialURL+"sanvi/api/guias/ver?cod=getDis").then(function (response) {
      console.log(response.data);
      setDista(response.data); 
      

    });

    // Get Sub Destinos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getSubDes")
    .then(function (response) {
      console.log(response.data);
      setSubde(response.data);
    });

}


const edit = (event) => {
  const cual = event.target.id;
  var element = document.getElementsByClassName('inp'+cual);
  var element2 = document.getElementsByClassName('span'+cual);
  var element3 = document.getElementsByClassName('fondo'+cual);
  if(edi == true){
    for(var i=0; i< element.length; i++){
      element[i].classList.remove("nover");
      
  }
  for(var i=0; i< element2.length; i++){
    element2[i].classList.add("nover");
  }

  for(var i=0; i< element3.length; i++){
    element3[i].classList.add("editando");
  }
  // setEdir(false);
  }

//   if(edi == false){
// for(var i=0; i< element.length; i++){
//       element[i].classList.add("nover");
      
//   }
//   for(var i=0; i< element2.length; i++){
//     element2[i].classList.remove("nover");
//   }
//   for(var i=0; i< element3.length; i++){
//     element3[i].classList.remove("editando");
//   }
//   setEdir(true);
//   }

  

    // window.print();
    // element.classList.remove("nada");

}


  //Borrar Row. 
  const delel = (event) => {
    const cual = event.target.id;

    setDl(values => ({...values, ['cual']: cual}));
    setDl(values => ({...values, ['codigo']: 'Dl'}));
    //showSwal();
    window.location.reload();
  }

  useEffect(() => { //Cuando cambie la variable "dl"
    axios.post(rialURL+'sanvi/api/servicios/borrar', dl).then(function(response){
          console.log(response.data);
      
          
        });
    
    }, [dl]); 


const ed_dis = (event) => {

  var tab = event.currentTarget.getAttribute("name");
  var value = '';

  // if(tab == 'distancia' || tab == 'valor'){
  //   value = event.currentTarget.textContent;

  // }else{
    
  // }
  value = event.currentTarget.value;
  var idi = event.currentTarget.id;

  // console.log(value);
  setEdDis(values => ({...values, ['val']: value}));
  setEdDis(values => ({...values, ['idi']: idi}));
  setEdDis(values => ({...values, ['tab']: tab}));
  setEdDis(values => ({...values, ['codigo']: 'edDis'}));
  //showSwal();
  
  
  
}


const crt = () => {

  // Get Ubicaciones
  axios
  .get(rialURL + "sanvi/api/guias/ver?ncl=ncl&que="+que)
  .then(function (response) {
    console.log(response.data);
  });
  
  window.location.reload();
}

useEffect(() => { //Cuando cambie el editar
  axios.post(rialURL+'sanvi/api/servicios/update', edDis).then(function(response){
    //console.log(response.data);
    //history.push('/Ver');
});


}, [edDis]); 


  return (
    <div id="bod" className="content-wrapper">
      {/* <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Ingreso Distancia</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div>

                    <div className="form-group">
                        <label htmlFor="nombre">Ubicacion</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="ubicacion"
                          id="ubicacion"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Ubicacion</option>
                          {ubi.map((u, key) => (
                            <option key={key} value={u.ubicacion}>
                              {u.ubicacion}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="nombre">Destino</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="destino"
                          id="destino"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Destino</option>
                          {des.map((u, key) => (
                            <option key={key} value={u.destino}>
                              {u.destino}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Distancia</label>
                        <input
                          type="text"
                          className="form-control inputi"
                          id="distancia"
                          name="distancia"
                          placeholder="ingrese distancia"
                          onChange={handleChange}
                          
                          required/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Valor</label>
                        <input
                          type="text"
                          className="form-control inputi"
                          id="valor"
                          name="valor"
                          placeholder="ingrese valor"
                          onChange={handleChange}
                          
                          required/>
                      </div>


                      <div className="form-group">
                        <label htmlFor="nombre">Digitador</label>
                        <input
                          type="text"
                          className="form-control inputi"
                          id="digitador"
                          name="digitador"
                          placeholder="ingrese Nombre"
                          defaultValue={que2}
                          readOnly
                        />
                      </div>

                    </div>
                  </div>

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Ingresar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-63">
              <div className="card card-primary">
              <div className="card-header">
                  <h3 className="card-title">Ingreso Distancia</h3>
                  <button onClick={crt} id="sos2" className="sombra buton fa-solid fa-plus"> </button>
                </div>
        <div className="card-body">


        <table id="tbodyx" className="table table-striped cabesa">
            <thead className="">
              <tr className="hstiky">
                <th>Ubicacion</th>
                <th>Destino</th>
                <th>Subdestino</th>
                <th>Distancia</th>
                <th>Valor</th>
                <th>Creador</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {/* <textarea type="text" className="form-control inpDT" name="n_completo" id={user.id} defaultValue={user.n_completo}  placeholder="" onBlur={handleChange}/>  optionsi.concat({ ididi: user.id})*/}
            <tbody id="tbodyx2" className="noborder">
              
            {dista.map((user, key) =>
              <tr className={' fondo'+user.id} id ={user.id} key={key}>
                <td><span className={'form-control-edit span'+user.id}>{user.ubicacion}</span><select  class={"form-control selc2 sombra nover inp"+user.id} defaultValue={user.ubicacion} name="ubicacion" id={user.id} onChange={ed_dis}>
                           <option value="0">Seleccione Ubicacion</option>
                           {ubi.map((u, key) => (
                            <option key={key} value={u.ubicacion}>
                              {u.ubicacion}
                            </option>
                          ))}

                         </select>
                         
                         
                         
                         
                         </td>
                <td><span className={'form-control-edit span'+user.id}>{user.destino}</span><select  class={"form-control selc2 sombra nover inp"+user.id} defaultValue={user.destino} name="destino" id={user.id} onChange={ed_dis} >
                           <option value="0">Seleccione Destino</option>
                           {des.map((u, key) => (
                            <option key={key} value={u.destino}>
                              {u.destino}
                            </option>
                          ))}subde

                         </select></td>
                <td><span className={'form-control-edit span'+user.id}>{user.subdestino}</span><select  class={"form-control selc2 sombra nover inp"+user.id} defaultValue={user.subdestino} name="subdestino" id={user.id} onChange={ed_dis} >
                           <option value="">Seleccione SubDestino</option>
                           {subde.map((u, key) => (
                            <option key={key} value={u.subdestino}>
                              {u.subdestino}
                            </option>
                          ))}

                         </select></td>         
                <td><span className={'form-control-edit span'+user.id}>{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.distancia)}</span><input
                          defaultValue={new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.distancia)}
                          type="tel"
                          className={"form-control selc3 sombra nover inp"+user.id}
                          id={user.id}
                          name="distancia"
                          placeholder=""
                          onChange={ed_dis}/></td>
                <td><span className={'form-control-edit span'+user.id}>{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.valor)}</span><input
                          defaultValue={new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.valor)}
                          type="tel"
                          className={"form-control selc3 sombra nover inp"+user.id}
                          id={user.id}
                          name="valor"
                          placeholder=""
                          onChange={ed_dis}/></td>
                <td><span contenteditable="false" className="form-control-edit pami" name="digitador" id={user.id} >{user.digitador}</span></td>
                <td><i id={user.id} value ="Borrar" onClick={swal_borrar} class="fa-solid fa-trash-can btn btn-primary btnr"></i></td>
                <td><i id={user.id} onClick={edit} class="btn btn-primary btng fa-regular fa-pen-to-square"></i></td>
              </tr>
              )}
                
              </tbody>
              <tfoot className="">
              <tr className="hstiky">
              <th>Ubicacion</th>
                <th>Destino</th>
                <th>Subdestino</th>
                <th>Distancia</th>
                <th>Valor</th>
                <th>Creador</th>
                <th></th>
                <th></th>
            </tr>
              
              
            </tfoot>
            
          </table>
        </div>
              </div>
            </div>
          </div>
        </div>
      </section>
                

    </div>
  )
}
