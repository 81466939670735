import React from 'react'
import { useState } from "react"; 
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rialURL, que2} from '../App.js'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


export default function Destinos() {

    const history = useHistory();
    const [inputs,setInputs] = useState({});

    // Modal de éxito
  const swal_bien = () => {
    withReactContent(Swal).fire({
      title: "Bien",
      text: "Ingresado Con Éxito",
      icon: "success",
    });
  };
    
  
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}));
      setInputs((values) => ({ ...values, ["digitador"]: que2 }));
      setInputs(values => ({...values, ['codigo']: 'ingDes'}));
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      axios.post(rialURL+'sanvi/api/user/save', inputs).then(function(response){
        console.log(response.data);
        swal_bien();
        document.getElementById("destino").value = "";
        setInputs(null); // limpia lo que se envia 

        //history.push('/Ver');
    });
  
      //console.log(inputs);
    }


  return (
    <div id='bod' className="content-wrapper">
    <section className="content-header">
      
    </section>

    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Crear Destinos</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="nombre">Destinos</label>
                    <input
                      type="text"
                      className="form-control"
                      id="destino"
                      name="destino"
                      onChange={handleChange}
                      placeholder="ingrese destino"
                      required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Digitador</label>
                    <input
                      type="text"
                      className="form-control"
                      id="digitador"
                      name="digitador"
                      defaultValue={que2}
                      placeholder="Tema"
                    readOnly/>
                  </div>
                  
                </div>

                <div className="card-footer">
                  <button 
                  id="sos" 
                  className="btn btn-primary">
                    Ingresar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
