import React from 'react'
import jsPDF from 'jspdf';
import { useState, useEffect, useRef } from "react";
import html2canvas from 'html2canvas';
import moment from 'moment';
import { format } from "date-fns";
import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
} from "chart.js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export default function E_patente() {

// Inputs elegidos
const [patt, setPatt] = useState();
// meses
const month = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","agosto","Septiembre","Octubre","Noviembre","Diciembre"];


// Variables Tarjetas
const [lts, setLTS] = useState();
const [odo, setOdo] = useState();
const [rend, setRend] = useState();


// Meses grafico de barra x Mes anual

// Odometros
const [odo1, setOdo1] = useState([]);
const [odo2, setOdo2] = useState([]);
const [odo3, setOdo3] = useState([]);
const [odo4, setOdo4] = useState([]);
const [odo5, setOdo5] = useState([]);
const [odo6, setOdo6] = useState([]);
const [odo7, setOdo7] = useState([]);
const [odo8, setOdo8] = useState([]);
const [odo9, setOdo9] = useState([]);
const [odo10, setOdo10] = useState([]);
const [odo11, setOdo11] = useState([]);
const [odo12, setOdo12] = useState([]);  


// Litros
const [lit1, setLit1] = useState([]);
const [lit2, setLit2] = useState([]);
const [lit3, setLit3] = useState([]);
const [lit4, setLit4] = useState([]);
const [lit5, setLit5] = useState([]);
const [lit6, setLit6] = useState([]);
const [lit7, setLit7] = useState([]);
const [lit8, setLit8] = useState([]);
const [lit9, setLit9] = useState([]);
const [lit10, setLit10] = useState([]);
const [lit11, setLit11] = useState([]);
const [lit12, setLit12] = useState([]);  



// Meses grafico de Lineas Anual Gastos
const [enero_gas, setEnero_gas] = useState([]);
const [febrero_gas, setFebrero_gas] = useState([]); 
const [marzo_gas, setMarzo_gas] = useState([]); 
const [abril_gas, setAbril_gas] = useState([]); 
const [mayo_gas, setMayo_gas] = useState([]); 
const [junio_gas, setJunio_gas] = useState([]); 
const [julio_gas, setJulio_gas] = useState([]); 
const [agosto_gas, setAgosto_gas] = useState([]); 
const [septiembre_gas, setSeptiembre_gas] = useState([]); 
const [octubre_gas, setOctubre_gas] = useState([]); 
const [noviembre_gas, setNoviembre_gas] = useState([]); 
const [diciembre_gas, setDiciembre_gas] = useState([]); 

// Meses grafico de Lineas 1 Petroleo
const [enero3, setEnero3] = useState([]); // 
const [febrero3, setFebrero3] = useState([]); 
const [marzo3, setMarzo3] = useState([]); 
const [abril3, setAbril3] = useState([]); 
const [mayo3, setMayo3] = useState([]); 
const [junio3, setJunio3] = useState([]); 
const [julio3, setJulio3] = useState([]); 
const [agosto3, setAgosto3] = useState([]); 
const [septiembre3, setSeptiembre3] = useState([]); 
const [octubre3, setOctubre3] = useState([]); 
const [noviembre3, setNoviembre3] = useState([]); 
const [diciembre3, setDiciembre3] = useState([]); 

// Meses grafico de barra Produccion
const [enero4, setEnero4] = useState([]); // 
const [febrero4, setFebrero4] = useState([]); 
const [marzo4, setMarzo4] = useState([]); 
const [abril4, setAbril4] = useState([]); 
const [mayo4, setMayo4] = useState([]); 
const [junio4, setJunio4] = useState([]); 
const [julio4, setJulio4] = useState([]); 
const [agosto4, setAgosto4] = useState([]); 
const [septiembre4, setSeptiembre4] = useState([]); 
const [octubre4, setOctubre4] = useState([]); 
const [noviembre4, setNoviembre4] = useState([]); 
const [diciembre4, setDiciembre4] = useState([]);


// Meses grafico de barra 2 Petroleo
const [enero, setEnero] = useState([]); // 
const [febrero, setFebrero] = useState([]); 
const [marzo, setMarzo] = useState([]); 
const [abril, setAbril] = useState([]); 
const [mayo, setMayo] = useState([]); 
const [junio, setJunio] = useState([]); 
const [julio, setJulio] = useState([]); 
const [agosto, setAgosto] = useState([]); 
const [septiembre, setSeptiembre] = useState([]); 
const [octubre, setOctubre] = useState([]); 
const [noviembre, setNoviembre] = useState([]); 
const [diciembre, setDiciembre] = useState([]); 

// Meses grafico de barra 2 Produccion
const [enero2, setEnero2] = useState([]); // 
const [febrero2, setFebrero2] = useState([]); 
const [marzo2, setMarzo2] = useState([]); 
const [abril2, setAbril2] = useState([]); 
const [mayo2, setMayo2] = useState([]); 
const [junio2, setJunio2] = useState([]); 
const [julio2, setJulio2] = useState([]); 
const [agosto2, setAgosto2] = useState([]); 
const [septiembre2, setSeptiembre2] = useState([]); 
const [octubre2, setOctubre2] = useState([]); 
const [noviembre2, setNoviembre2] = useState([]); 
const [diciembre2, setDiciembre2] = useState([]); 


// variables Tabla total
const [todo, setTodo] = useState([]); 
const [todo2, setTodo2] = useState([]);

// Variables tablas
const [tabpe, setTab_pe] = useState([]); // Detalle Petróleo
const [tabgas, setTab_gas] = useState([]); // Detalle Gastos
const [tabgas2, setTab_gas2] = useState([]); // Resumen Gastos

// variables Grafico barras por mes
const [bar1, setBar1] = useState([]); 

// variables Grafico linear por dia
const [li, setLi] = useState([]); 

// variables Grafico linear por dia Gastos
const [li_gas, setLi_gas] = useState([]); 

// Variables de los combobox
const [pat, setPat] = useState([]); // patentes
const [des, setDes] = useState([]); // destinos
const [docs, setDocs] = useState([]); // documentos

const [prod_d, setProd_d] = useState([]); // Produccion Detalle
const [prod_t, setProd_t] = useState([]); // Produccion Total

// Guardar variables
const [combi, setCombi] = useState([]); 
const [F1, setF1] = useState([]); 
const [F2, setF2] = useState([]); 


// Inputs del formulario
const [inputs, setInputs] = useState({});

// Tabla detalle Petroleo totales
var a = 0;
var e = 0;
var e2 = 0;
var i = 0;
var o = 0;
var o_todo = 0;
var g_todo = 0;
var por_todo = 0;
var prod_todo = 0;
var sal_todo = 0;

var o_todo2 = 0;
var g_todo2 = 0;
var por_todo2 = 0;
var prod_todo2 = 0;
var sal_todo2 = 0;

var v_gas = 0;
var v_gas2 = 0;
var base1 = 0;
var base2 = 0;
var total1 = 0;
var total_t =0;
// Para enviar a otras paginas
const history = useHistory();
const hoy = new Date();


//Botón pdf
 const divRef = useRef();
 const divRef2 = useRef();
 const divRef3 = useRef();
 const divRef4 = useRef();
 const divRef5 = useRef();
 const divRef6 = useRef();

const handleDownloadPdf = () => {

  if(window.matchMedia("(orientation: portrait)").matches){
    swal_rote();
  }else{
    document.title='Informe Fechas '+moment(F1).format('DD-MM-yyyy')+' y '+moment(F2).format('DD-MM-yyyy')+'.pdf';
    window.print();
    // const input = divRef.current;

    // html2canvas(input, { scale: 2 }).then(canvas => {
    //   let imgData = canvas.toDataURL('image/jpeg', 1.0);
    //   let pdf = new jsPDF('p', 'pt', 'a4');
    //   let imgWidth = 595.28; // Width of A4 page in points
    //   let imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   let pageHeight = 841.89; // Height of A4 page in points
    //   let heightLeft = imgHeight;
    //   let position = 0;
    //   let pageCount = 0;
    
    //   // Add the first page
    //   pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
    //   heightLeft -= pageHeight;
    
    //   // Add subsequent pages
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
    //     heightLeft -= pageHeight;
    //     pageCount++;
    //   }
    
    //   // Save the PDF
    //   pdf.save('Informe Fechas '+moment(F1).format('DD-MM-yyyy')+' y '+moment(F2).format('DD-MM-yyyy')+'.pdf');
    // });
    

    // var element = document.getElementById('formi');
    // element.classList.add("nada");
    // window.print();
    // element.classList.remove("nada");


  } //end else








  
};










const options = {
    responsive: true,
    plugins:{
      title: {
         display: true,
         text: 'Patente: '+combi
         }
     }
};

const liOptions = {
  responsive: true,
  plugins:{
    title: {
       display: true,
       text: 'Patente: '+combi+'- Mes: '+month[hoy.getMonth()]
       }
   }
};

var barMensual = {
  labels:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","agosto","Septiembre","Octubre","Noviembre","Diciembre"],
  datasets:[
      {
          label: "Odometro",
          data:[parseInt(odo1), parseInt(odo2), parseInt(odo3), parseInt(odo4), parseInt(odo5), parseInt(odo6), parseInt(odo7), parseInt(odo8), parseInt(odo9), parseInt(odo10), parseInt(odo11),parseInt(odo12)],
          backgroundColor:["rgba(0,255,0, 0.5)"],
          borderColor:["rgba(0,255,0, 1)"],
          borderWidth: 1,
      },
      {
          label: "Litros",
          data: [parseInt(lit1), parseInt(lit2), parseInt(lit3), parseInt(lit4), parseInt(lit5), parseInt(lit6), parseInt(lit7), parseInt(lit8), parseInt(lit9), parseInt(lit10), parseInt(lit11), parseInt(lit12)],
          backgroundColor:["rgba(0,0,255, 0.5)"],
          borderColor:["rgba(0,0,255, 1)"],
          borderWidth: 1,
      },
      
  ]
}



var bar_pro = {
  labels:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","agosto","Septiembre","Octubre","Noviembre","Diciembre"],
  datasets:[
      {
          label: "Producción",
          data:[enero4, febrero4, marzo4, abril4, mayo4, junio4, julio4, agosto4, septiembre4, octubre4, noviembre4,diciembre4],
          backgroundColor:["rgba(0,255,0, 0.5)"],
          borderColor:["rgba(0,255,0, 1)"],
          borderWidth: 1,
      }
  ]
}






var barChartData = {
    labels:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","agosto","Septiembre","Octubre","Noviembre","Diciembre"],
    datasets:[
        {
            label: "Petróleo",
            data:[enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre,diciembre],
            backgroundColor:["rgba(0,255,0, 0.5)"],
            borderColor:["rgba(0,255,0, 1)"],
            borderWidth: 1,
        },
        {
            label: "Produccion",
            data: [enero2, febrero2, marzo2, abril2, mayo2, junio2, julio2, agosto2, septiembre2, octubre2, noviembre2,diciembre2],
            backgroundColor:["rgba(0,0,255, 0.5)"],
            borderColor:["rgba(0,0,255, 1)"],
            borderWidth: 1,
        },
    ]
}

var LineartData1 = {
  labels:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
  datasets:[
      {
          label: "Rendimiento Mensual",
          data:[enero3, febrero3, marzo3, abril3, mayo3, junio3, julio3, agosto3, septiembre3, octubre3, noviembre3,diciembre3],
          backgroundColor:["rgba(0,0,255, 0.5)"],
          borderColor:["rgba(0,0,255, 1)"],
          borderWidth: 1,
      },
      
  ]
}


var LineartData_gas2 = {
  labels:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
  datasets:[
      {
          label: "Rendimiento Mensual",
          data:[enero_gas, febrero_gas, marzo_gas, abril_gas, mayo_gas, junio_gas, julio_gas, agosto_gas, septiembre_gas, octubre_gas, noviembre_gas, diciembre_gas],
          backgroundColor:["rgba(0,0,255, 0.5)"],
          borderColor:["rgba(0,0,255, 1)"],
          borderWidth: 1,
      },
      
  ]
}








//Grafico Rendimiento por dia
var liDias = [];
li.map((cli, key) => liDias.push(moment(cli.dia).format('DD/MM')));
var liData = [];
li.map((cli, key) => liData.push(cli.rendimiento));
var LineartData2 = {
  labels: liDias,
  datasets:[
      {
          label: "Rendimiento Diario",
          data: liData,
          backgroundColor:["rgba(0,255,0, 0.5)"],
          borderColor:["rgba(0,255,0, 1)"],
          borderWidth: 1,
      },
      
  ]
}

//Grafico Gasto por dia
var liDias_gas = [];
li_gas.map((cli, key) => liDias_gas.push(moment(cli.fecha).format('DD/MM')));
var liData_gas = [];
li_gas.map((cli, key) => liData_gas.push(cli.valor));
var LineartData_gas = {
  labels: liDias_gas,
  datasets:[
      {
          label: "Gasto Diario",
          data: liData_gas,
          backgroundColor:["rgba(0,255,0, 0.5)"],
          borderColor:["rgba(0,255,0, 1)"],
          borderWidth: 1,
      },
      
  ]
}


// Rote el telefono para Descargar el PDF
const swal_rote = () => {
  withReactContent(Swal).fire({
    title: "Error",
    text: "Rote el telefono para Descargar el PDF",
    icon: "error",
  });
};

// Modal de éxito
const swal_bien = () => {
  withReactContent(Swal).fire({
    title: "Bien",
    text: "Ingresado Con Éxito",
    icon: "success",
  });
};

// Se llaman las Funciones
useEffect(() => {
  
  
  // Carga todo
   getAll();
}, []);




const diario1 = (ele) => {

  let name = ele.target.name;
  let value = ele.target.value;
  value = moment(value).format('MM');
  
   // Get grafico lineas Diario
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_lin2&pat="+combi+"&month="+value)
   .then(function (response) {
     //console.log(response.data);
     setLi(response.data);
 
      
 
      
     
   });
}



const diario_gas = (ele) => {
  
  let pickm_gas = ele.target.value;
  pickm_gas = moment(pickm_gas).format('MM');
    // Get grafico lineas Diario Gastos
  axios
  .get(rialURL + "sanvi/api/guias/ver?cod=get_lin_gas&pat="+combi+"&month="+pickm_gas)
  .then(function (response) {
    //console.log(response.data);
    setLi_gas(response.data);

    

     
    
  });

}







const aniR = (e) => {
  var R = e.target.id;
  var resu = document.getElementById('resu');
  var gasto = document.getElementById('gasto');
  var petr = document.getElementById('petro');
  var produc = document.getElementById('produccion');

  //console.log(R);
  if (R =='petro_s' || R =='petro_sf') {
    if (petr.classList.contains('animacion')) {

      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");
  
      // Quitar produccion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
  
      // Quitar la clase animacion
      petr.classList.remove("animacion");
      petr.classList.add("invisible");
      
      // Esperar un breve tiempo antes de agregar la clase nuevamente
      setTimeout(() => {
        petr.classList.add("animacion");
        petr.classList.remove("invisible");
      }, 50); // Puedes ajustar el tiempo en milisegundos si es necesario
    } else {
  
  
      // Si la clase no está presente, agregarla directamente
      petr.classList.add("animacion");
      petr.classList.remove("invisible");
  
      // Quitar petroleo
      produc.classList.remove("animacion");
      produc.classList.add("invisible");
  
      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
    }
  
  }
  if(R=='produc_s'){
    if (produc.classList.contains('animacion')) {

      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");
  
      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
  
      // Quitar la clase animacion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");
      
      // Esperar un breve tiempo antes de agregar la clase nuevamente
      setTimeout(() => {
        produc.classList.add("animacion");
        produc.classList.remove("invisible");
      }, 50); // Puedes ajustar el tiempo en milisegundos si es necesario
    } else {
  
  
      // Si la clase no está presente, agregarla directamente
      produc.classList.add("animacion");
      produc.classList.remove("invisible");
  
      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");
  
      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
    }
  }
  if(R=='gasto_s'){
    if (gasto.classList.contains('animacion')) {

      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");
      
      // Quitar produccion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
  
      // Quitar la clase animacion
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");
      
      // Esperar un breve tiempo antes de agregar la clase nuevamente
      setTimeout(() => {
        gasto.classList.add("animacion");
        gasto.classList.remove("invisible");
      }, 50); // Puedes ajustar el tiempo en milisegundos si es necesario
    } else {
  
  
      // Si la clase no está presente, agregarla directamente
      gasto.classList.add("animacion");
      gasto.classList.remove("invisible");
  
      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");

      // Quitar produccion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");

      // Quitar resumen
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
  
    }
  }
  if(R=='resu_s'){
    if (resu.classList.contains('animacion')) {

      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");
      
      // Quitar produccion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");

      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");
  
  
      // Quitar la clase animacion
      resu.classList.remove("animacion");
      resu.classList.add("invisible");
      
      // Esperar un breve tiempo antes de agregar la clase nuevamente
      setTimeout(() => {
        resu.classList.add("animacion");
        resu.classList.remove("invisible");
      }, 50); // Puedes ajustar el tiempo en milisegundos si es necesario
    } else {
  
  
      // Si la clase no está presente, agregarla directamente
      resu.classList.add("animacion");
      resu.classList.remove("invisible");
  
      // Quitar petroleo
      petr.classList.remove("animacion");
      petr.classList.add("invisible");

      // Quitar produccion
      produc.classList.remove("animacion");
      produc.classList.add("invisible");

      // Quitar gasto
      gasto.classList.remove("animacion");
      gasto.classList.add("invisible");
  
    }
  }





  
  
  
}

// Registra las entradas de los inputs
const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs((values) => ({ ...values, [name]: value }));
  setInputs((values) => ({ ...values, ["digitador"]: que2 }));
  setInputs((values) => ({ ...values, ["cod"]: "getE_barra3" }));
  setInputs((values) => ({ ...values, ["codigo"]: "getE_barra3" }));
};
// Guarda patente
const combito = (event) => {
  // const name = event.target.name;
  const value = event.target.value;
  setCombi(value);
  
};

// Guarda fecha1
const f1 = (event) => {
  const value = event.target.value;
  setF1(value);
  
};

// Guarda combobox
const f2 = (event) => {
  
  const value = event.target.value;
  setF2(value);
  
};

// Envia el formulario
const handleSubmit = (event) => {
  event.preventDefault();
  barra3();

  //Aparesca informacion Petróleo
  aniR(event);

  var navi2 = document.getElementById('pdf_b');
  var navi = document.getElementById('pdf_p');
  navi.appendChild(navi2);

  var pg= document.getElementById('pg');
  var pg2= document.getElementById('pg2');
  var pg3= document.getElementById('pg3');
  pg.classList.remove("invisible");
  pg2.classList.remove("invisible");
  pg3.classList.remove("invisible");
  pg.appendChild(pg2);
  //console.log(inputs);
};

// Funcion get para todos los comboBox
function getAll() {

 // Get Patentes
 axios
 .get(rialURL + "sanvi/api/guias/ver?cod=getPat")
 .then(function (response) {
   //console.log(response.data);
   setPat(response.data);
 });

       // Get Destinos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getDes")
    .then(function (response) {
      //console.log(response.data);
      setDes(response.data);
    });

   

    


} 
  // funcion para todos los graficos 
function barra3() {
   // Get/Post Barra 2 petroleo
   axios
   .post(rialURL + "sanvi/api/guias/ver",inputs)
   .then(function (response) {
    //  console.log(response.data);
     // Petroleo
     setEnero(response.data[0].enero);
     setFebrero(response.data[0].febrero);
     setMarzo(response.data[0].marzo);
     setAbril(response.data[0].abril);
     setMayo(response.data[0].mayo);
     setJunio(response.data[0].junio);
     setJulio(response.data[0].julio);
     setAgosto(response.data[0].agosto);
     setSeptiembre(response.data[0].septiembre);
     setOctubre(response.data[0].octubre);
     setNoviembre(response.data[0].noviembre);
     setDiciembre(response.data[0].diciembre);

     // Produccion
     setEnero2(response.data[0].enero2);
     setFebrero2(response.data[0].febrero2);
     setMarzo2(response.data[0].marzo2);
     setAbril2(response.data[0].abril2);
     setMayo2(response.data[0].mayo2);
     setJunio2(response.data[0].junio2);
     setJulio2(response.data[0].julio2);
     setAgosto2(response.data[0].agosto2);
     setSeptiembre2(response.data[0].septiembre2);
     setOctubre2(response.data[0].octubre2);
     setNoviembre2(response.data[0].noviembre2);
     setDiciembre2(response.data[0].diciembre2);
   });


   // Get Tabla Detalle Petroleo
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_pe&f1="+F1+"&f2="+F2+"&pat="+combi)
   .then(function (response) {
    // console.log(response.data);
     setTab_pe(response.data);
   });

    // Get Tabla Detalle Gastos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_gas&f1="+F1+"&f2="+F2+"&pat="+combi)
    .then(function (response) {
      //console.log(response.data);
      setTab_gas(response.data);
    });

    // Get Tabla Resumen Gastos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_gas2&f1="+F1+"&f2="+F2+"&pat="+combi)
    .then(function (response) {
      //console.log(response.data);
      setTab_gas2(response.data);
    });

    // Get variables tarjetitas
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=get_tar&f1="+F1+"&f2="+F2+"&pat="+combi)
    .then(function (response) {
     // console.log(response.data);
      setLTS(response.data[0].litros);
      setOdo(response.data[0].odometro);
    });

    // Get grafico lineas Anual
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=get_lin1&pat="+combi)
    .then(function (response) {
     // console.log(response.data);

      // rendimiento por mes
     setEnero3(response.data[0].enero);
     setFebrero3(response.data[0].febrero);
     setMarzo3(response.data[0].marzo);
     setAbril3(response.data[0].abril);
     setMayo3(response.data[0].mayo);
     setJunio3(response.data[0].junio);
     setJulio3(response.data[0].julio);
     setAgosto3(response.data[0].agosto);
     setSeptiembre3(response.data[0].septiembre);
     setOctubre3(response.data[0].octubre);
     setNoviembre3(response.data[0].noviembre);
     setDiciembre3(response.data[0].diciembre);

      
      
    });

    // Get grafico lineas Anual
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=get_lin_gasa&pat="+combi)
    .then(function (response) {
      console.log(response.data)
     setEnero_gas(response.data[0].enero);
     setFebrero_gas(response.data[0].febrero);
     setMarzo_gas(response.data[0].marzo);
     setAbril_gas(response.data[0].abril);
     setMayo_gas(response.data[0].mayo);
     setJunio_gas(response.data[0].junio);
     setJulio_gas(response.data[0].julio);
     setAgosto_gas(response.data[0].agosto);
     setSeptiembre_gas(response.data[0].septiembre);
     setOctubre_gas(response.data[0].octubre);
     setNoviembre_gas(response.data[0].noviembre);
     setDiciembre_gas(response.data[0].diciembre);

      
      
    });

    

     // Get grafico Barras Anual x Mex
     axios
     .get(rialURL + "sanvi/api/guias/ver?cod=get_bar1&pat="+combi)
     .then(function (response) {
       //console.log(response.data);
       setBar1(response.data); 
       
       
     });


      // Get Tabla Resumen
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_resu&f1="+F1+"&f2="+F2)
   .then(function (response) {
     console.log(response.data);
     setTodo2(response.data);
     
   });

     // Get Tabla Total
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_todo&f1="+F1+"&f2="+F2+"&pat="+combi)
   .then(function (response) {
     console.log(response.data);
     setTodo(response.data);
     
   });  


  let pickm = document.getElementById("pickm").value;
  pickm = moment(pickm).format('MM');
    // Get grafico lineas Diario
  axios
  .get(rialURL + "sanvi/api/guias/ver?cod=get_lin2&pat="+combi+"&month="+pickm)
  .then(function (response) {
   // console.log(response.data);
    setLi(response.data);

    

     
    
  });

  let pickm_gas = document.getElementById("pickm_gas").value;
  pickm_gas = moment(pickm_gas).format('MM');
    // Get grafico lineas Diario Gastos
  axios
  .get(rialURL + "sanvi/api/guias/ver?cod=get_lin_gas&pat="+combi+"&month="+pickm_gas)
  .then(function (response) {
    console.log(response.data);
    setLi_gas(response.data);

    

     
    
  });

 // Get Documentos
 axios
 .get(rialURL + "sanvi/api/guias/ver?cod=getDoc&f1="+F1+"&f2="+F2+"&pat="+combi)
 .then(function (response) {
   console.log(response.data);
   setDocs(response.data);
 });

 // Get Produccion detalle
 axios
 .get(rialURL + "sanvi/api/guias/ver?cod=getProd_d&f1="+F1+"&f2="+F2+"&pat="+combi)
 .then(function (response) {
   console.log(response.data);
   setProd_d(response.data);
 });

// Get Produccion Total
axios
.get(rialURL + "sanvi/api/guias/ver?cod=getProd_t&f1="+F1+"&f2="+F2+"&pat="+combi)
.then(function (response) {
  console.log(response.data);
  //setLTS(response.data[0].litros);
  //setOdo(response.data[0].odometro);

  setProd_t(response.data);
});
    
// Get grafico Barras Produccion
axios
.get(rialURL + "sanvi/api/guias/ver?cod=get_bar_pro&pat="+combi)
.then(function (response) {
 // console.log(response.data);

  // rendimiento por mes
 setEnero4(response.data[0].enero);
 setFebrero4(response.data[0].febrero);
 setMarzo4(response.data[0].marzo);
 setAbril4(response.data[0].abril);
 setMayo4(response.data[0].mayo);
 setJunio4(response.data[0].junio);
 setJulio4(response.data[0].julio);
 setAgosto4(response.data[0].agosto);
 setSeptiembre4(response.data[0].septiembre);
 setOctubre4(response.data[0].octubre);
 setNoviembre4(response.data[0].noviembre);
 setDiciembre4(response.data[0].diciembre);

  
  
});










}



 
// Se llaman las Funciones
useEffect(() => {
  bar1.map((cli) => {
    console.log(cli.mes);
  
    switch (String(cli.mes)) {
      case '1':
        setOdo1(cli.odometro);
        setLit1(cli.litros);
        break;
      case '2':
        setOdo2(cli.odometro);
        setLit2(cli.litros);
        break;
      case '3':
        setOdo3(cli.odometro);
        setLit3(cli.litros);
        break;
      case '4':
        setOdo4(cli.odometro);
        setLit4(cli.litros);
        break;
      case '5':
        setOdo5(cli.odometro);
        setLit5(cli.litros);
        break;
      case '6':
        setOdo6(cli.odometro);
        setLit6(cli.litros);
        break;
      case '7':
        setOdo7(cli.odometro);
        setLit7(cli.litros);
        break;
      case '8':
        setOdo8(cli.odometro);
        setLit8(cli.litros);
        break;
      case '9':
        setOdo9(cli.odometro);
        setLit9(cli.litros);
        break;
      case '10':
        setOdo10(cli.odometro);
        setLit10(cli.litros);
        break;
      case '11':
        setOdo11(cli.odometro);
        setLit11(cli.litros);
        break;
      case '12':
        setOdo12(cli.odometro);
        setLit12(cli.litros);
        break;
      default:
        // No additional code needed for the default case
        break;
    }
  });
}, [bar1]);



  return (
    <div id="bod" className="content-wrapper ">
      
      <section id='formi' className="content arriba">
        <div className="container-fluid auto ">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary ">
                <div className="card-header">
                  <h3 className="card-title">Produccion Conductores</h3>
                </div>

                <form id='petro_sf' onSubmit={handleSubmit}>
                  <div className="card-body">
                    

                    <div style={{display:"grid"}}> 
                    <div className="form-group derecha">
                        <label htmlFor="dia">Fecha 1</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha1"
                            name="fecha1"
                            onChange={handleChange}
                            onBlur={f1}
                            required/>
                        </div>
                      </div>

                      <div className="form-group columna">
                        <label htmlFor="dia">Fecha 2</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha2"
                            name="fecha2"
                            onChange={handleChange}
                            onBlur={f2}
                            required/>
                        </div>
                      </div>

                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Patente</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="patente"
                          id="patente"
                          onChange={handleChange}
                          onBlur={combito}
                          required>
                          <option value="0">Seleccione Patente</option>
                          {pat.map((u, key) => (
                            <option key={key} value={u.patente}>
                              {u.patente}
                            </option>
                          ))}
                        </select>
                      </div>
                      

                      

                      

                    </div>
                  

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Consultar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      

           <section className="content arriba">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
            <ol id="pg2" class="bread bread2 invisible letra-peque">
           <li class="breadcrumb-item"><span className='cursor' id="petro_s" onClick={aniR} >Petróleo</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="gasto_s" onClick={aniR} >Gastos</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="produc_s" onClick={aniR}>Produccion</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="resu_s" onClick={aniR}>Resumen</span></li>
           </ol>
           <ol id="pg3" class="bread  invisible letra-peque">
           <li class="breadcrumb-item"><span className='cursor' id="petro_s" onClick={aniR} >Petróleo</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="gasto_s" onClick={aniR} >Gastos</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="produc_s" onClick={aniR}>Produccion</span></li>
           <li class="breadcrumb-item"><span className='cursor' id="resu_s" onClick={aniR}>Resumen</span></li>
           </ol>
            </div>
          </div>
        </div>
      </section>

      <div id="produccion" className='invisible arr' >
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Patente: <strong>{combi}</strong> entre las fechas <strong>{moment(F1).format('DD/MM/yyyy')}</strong> y <strong>{moment(F2).format('DD/MM/yyyy')}</strong> </h3>
                </div>
                <div className="card-body">
                <div className="card-body">
        <div className="row cc">
        {prod_t.map((cli, key) => <span key={key} style={{display:'none'}}>{total_t += parseInt(cli.produccion) || 0}</span> )}
        <div className="col-lg-3 col-6"> 
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
              <p>Patente: <strong>{combi}</strong></p> 
                <h3>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(total_t)}</h3>
                <p style={{fontSize: '1.3rem'}}>Produccion Total</p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-bag" /> */}
              </div>
              <a href="#" className="small-box-footer">
               <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          
        {prod_t.map((cli, key) =>
        
        <div key={key} className="col-lg-3 col-6">
          
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
              <p>Patente: <strong>{cli.patente}</strong> Conductor: <strong>{cli.conductor}</strong></p> 
                <h3>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.produccion)}</h3>
                <p style={{fontSize: '1.3rem'}}>Produccion</p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-bag" /> */}
              </div>
              <a href="#" className="small-box-footer">
               <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div> )}
          
          
          
      
        </div>
      </div> 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 style={{textWrap:'text-wrap: balance'}} className="centro">Produccion Patente: <strong>{combi}</strong> - Año: <strong>{moment(hoy).format('yyyy')}</strong> </h3>
                </div>
                <div className="card-body chart_container">
                <Bar options={liOptions} data={bar_pro} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Produccion Detalle</h3>
                </div>
                <div className="card-body">

                <div className="card-body table-responsive p-0 " style={{height: "auto",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Fecha</th>
                <th>Conductor</th>
                <th>Patente</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>SubDestino</th>
                <th>Base</th>
                <th>Distancia</th>
                <th>Valor</th>
                <th>Total</th>
            </tr>
          </thead>
          <tbody>

          {prod_d.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>

              {/* Base */}
              <span style={{display:'none'}}>{base2 += parseInt(cli.capacidad) || 0}</span> 
               {/* Total */}
               <span style={{display:'none'}}>{total1 += parseInt(cli.produccion) || 0}</span> 
 
                <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
                <td>{cli.conductor}</td>
                <td>{cli.patente}</td>
                <td>{cli.ubicacion}</td>
                <td>{cli.destino}</td>
                <td>{cli.subdestino}</td>
                <td>{cli.capacidad}</td>
                <td>{cli.distancia}</td>
                <td>{cli.valor}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.produccion)}</td>
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{base2}</th>
                <th></th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(total1)}</th>
            </tr>
          </thead>
        </table>
      </div>
                    
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>

      <div id="gasto" className='invisible arr' >
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Resumen Gasto</h3>
                </div>
                <div className="card-body">

                <div className="card-body table-responsive p-0 " style={{height: "auto",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                
                <th>Patente</th>
                <th>Valor</th>
                <th>Conductor</th>
            </tr>
          </thead>
          <tbody>

          {tabgas2.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>
              
               {/* Total */}
            <span style={{display:'none'}}>{v_gas2 += parseInt(cli.gasto)}</span> 
 
               
                <td>{cli.patente}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.gasto)}</td>
                <td>{cli.conductor}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(v_gas2)}</th>
                <th></th>
            </tr>
          </thead>
        </table>
      </div>
                    
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 style={{textWrap:'text-wrap: balance'}} className="centro">Gastos Patente: <strong>{combi}</strong> - Mes: <strong><input className='form-control3' defaultValue={moment(hoy).format('yyyy-MM')} type="month" onChange={diario_gas} id="pickm_gas" name="pickm_gas"/> </strong> </h3>
                </div>
                <div className="card-body chart_container">
                <Line options={liOptions} data={LineartData_gas} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Histórico Gastos Año <strong>{hoy.getFullYear()}</strong></h3>
                </div>
                <div className="chart_container">
                <Line options={options} data={LineartData_gas2} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Detalle Gasto</h3>
                </div>
                <div className="card-body">

                <div className="card-body table-responsive p-0 " style={{height: "auto",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Fecha</th>
                <th>Patente</th>
                <th>Valor</th>
                <th>Detalle</th>
                <th>Vale</th>
                <th>Conductor</th>
            </tr>
          </thead>
          <tbody>

          {tabgas.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>
              
               {/* Total */}
            <span style={{display:'none'}}>{v_gas += parseInt(cli.valor)}</span> 
 
               
                <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
                <td>{cli.patente}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.valor)}</td>
                <td><textarea readOnly>{cli.detalle}</textarea></td>
                <td>{cli.vale}</td>
                <td>{cli.conductor}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(v_gas)}</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
          </thead>
        </table>
      </div>
                    
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>

<div id="petro" className='invisible arr' >
<div ref={divRef}>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Resumen General</h3>
                </div>
                <div className="card-body">

                <div className="card-body table-responsive p-0 " style={{height: "auto",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Patente</th>
                <th>Conductor</th>
                <th>Gasto Petróleo</th>
                <th>Gastos</th>
                <th>%</th>
                <th>Produccion</th>
                <th>Saldo</th>
            </tr>
          </thead>
          <tbody>

          {todo.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>
            
              
               {/* Petro */}
            <span style={{display:'none'}}>{o_todo += parseInt(cli.petroleo) || 0}</span> 
            {/* Gastos */}
            <span style={{display:'none'}}>{g_todo += parseInt(cli.gasto) || 0}</span> 
            {/* % */}
            <span style={{display:'none'}}>{por_todo += parseInt(cli.porcentaje) || 0}</span> 
            {/* Produccion */}
            <span style={{display:'none'}}>{prod_todo += parseInt(cli.produccion) || 0}</span> 
            {/* Saldo */}
            <span style={{display:'none'}}>{sal_todo += parseInt(cli.saldo) || 0}</span> 
 
               
                <td>{cli.patente}</td>
                <td>{cli.conductor}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.petroleo)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.gasto)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.porcentaje)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.produccion)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.saldo)}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(o_todo)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(g_todo)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(por_todo)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(prod_todo)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(sal_todo)}</th>
            </tr>
          </thead>
        </table>
      </div>
                    
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      

      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Patente: <strong>{combi}</strong> entre las fechas <strong>{moment(F1).format('DD/MM/yyyy')}</strong> y <strong>{moment(F2).format('DD/MM/yyyy')}</strong> </h3>
                </div>
                <div className="card-body">
                <div className="card-body">
        <div className="row cc">
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{lts}</h3>
                <p>Litros </p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-bag" /> */}
              </div>
              <a href="#" className="small-box-footer">
               <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3>
                {odo}<span className='pequeño'>Km</span>
                </h3>
                <p> Odometro </p>
                
              </div>
              <div className="icon">
                {/* <i className="ion ion-stats-bars" /> */}
              </div>
              <a href="#" className="small-box-footer">
                 <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{(odo/lts).toFixed(2)}<span className='pequeño'>Km/L</span></h3>
                <p>Rendimiento</p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-pie-graph" /> */}
                
              </div>
              <a href="#" className="small-box-footer">
                 <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
        </div>
      </div> 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      

      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 style={{textWrap:'text-wrap: balance'}} className="centro">Rendimiento Patente: <strong>{combi}</strong> - Mes: <strong><input className='form-control3' defaultValue={moment(hoy).format('yyyy-MM')} type="month" onChange={diario1} id="pickm" name="pickm"/> </strong> </h3>
                </div>
                <div className="card-body chart_container">
                <Line options={liOptions} data={LineartData2} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Histórico Rendimiento Año <strong>{hoy.getFullYear()}</strong></h3>
                </div>
                <div className="chart_container">
                <Line options={options} data={LineartData1} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Petróleo vs Kilometraje Año <strong>{hoy.getFullYear()}</strong></h3>
                </div>
                <div className="chart_container">
                <Bar options={options} data={barMensual} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Detalle Petróleo Patente: <strong>{combi}</strong></h3>
                </div>
                <div className="card-body">
                
          <div className="card-body table-responsive p-0" style={{height: "fit-content",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Fecha</th>
                <th>Conductor</th>
                <th>Petróleo</th>
                <th>Valor</th>
                <th>Odometro</th>
                <th>Total</th>
            </tr>
          </thead>
          <tbody>
          {tabpe.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}> 
            {/* Petroleo */}
                <span style={{display:'none'}}>{a += parseInt(cli.litros)}</span>
           
            {/* Total */}
                <span style={{display:'none'}}>{o += parseInt(cli.litros*cli.valor)}</span>            
               
               
               
               
                <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
                <td>{cli.conductor}</td>
                <td>{cli.litros}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.valor)}</td>
                <td>{cli.odometro}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.litros*cli.valor)}</td>
                
              </tr>)}
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(a)}</th>
                <th></th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(o)}</th>
            </tr>
          </thead>
        </table>
      </div>
                
          
                    
                </div> 
                {/* final primer cardbody */}


                
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>

      <section className="content">
        <div className="container-fluid">
        <button id='pdf_b' className="btn dere btn-primary letra-peque" onClick={handleDownloadPdf}>Descargar PDF</button>
        </div>
      </section>
      
  </div>
  <div id="resu" className='invisible arr' >
  <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="centro">Resumen todas las Patentes<strong></strong></h3>
                </div>
                <div className="card-body">
                
          <div className="card-body table-responsive p-0" style={{height: "fit-content",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Patente</th>
                <th>Conductor</th>
                <th>Gasto Petróleo</th>
                <th>Gastos</th>
                <th>%</th>
                <th>Producción</th>
                <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
          {todo2.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>
            
              
               {/* Petro */}
            <span style={{display:'none'}}>{o_todo2 += parseInt(cli.petroleo) || 0}</span> 
            {/* Gastos */}
            <span style={{display:'none'}}>{g_todo2 += parseInt(cli.gasto) || 0}</span> 
            {/* % */}
            <span style={{display:'none'}}>{por_todo2 += parseInt(cli.porcentaje) || 0}</span> 
            {/* Produccion */}
            <span style={{display:'none'}}>{prod_todo2 += parseInt(cli.produccion) || 0}</span> 
            {/* Saldo */}
            <span style={{display:'none'}}>{sal_todo2 += parseInt(cli.saldo) || 0}</span> 
 
               
                <td>{cli.patente}</td>
                <td>{cli.conductor}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.petroleo)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.gasto)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.porcentaje)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.produccion)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.saldo)}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(o_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(g_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(por_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(prod_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(sal_todo2)}</th>
            </tr>
          </thead>
        </table>
      </div>
                
          
                    
                </div> 
                {/* final primer cardbody */}


                
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
      

      
    </div>
  )
}
