import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'
import { format } from "date-fns";
import moment from 'moment';



export default function Detalle_p() {

// Guardar variables
const [F1, setF1] = useState([]); 
const [F2, setF2] = useState([]); 

// Variables tablas
const [tabpe, setTab_pe] = useState([]); // Detalle Petróleo

// Tabla detalle Petroleo totales
var a = 0;

// Inputs del formulario
const [inputs, setInputs] = useState({});
// Modal de error
const swal_error = () => {
    withReactContent(Swal).fire({
      title: "Error",
      text: "Faltan campos por Rellenar",
      icon: "error",
    });
  };
// Registra las entradas de los inputs
const handleChange = (event) => {

    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
    setInputs((values) => ({ ...values, ["digitador"]: que2 }));
    setInputs((values) => ({ ...values, ["cod"]: "detalleP" }));
    setInputs((values) => ({ ...values, ["codigo"]: "detalleP" }));



    
  };

// Guarda fecha1
const f1 = (event) => {
  const value = event.target.value;
  setF1(value);
  
};

// Guarda combobox
const f2 = (event) => {
  
  const value = event.target.value;
  setF2(value);
  
};



const handleSubmit = (event) => {
    event.preventDefault();
    enviar();
    //console.log(inputs);
  };

  function enviar(){
    axios
   .post(rialURL + "sanvi/api/guias/ver",inputs)
   .then(function (response) {
    setTab_pe(response.data);
     //console.log(response.data);
    
     
   });
  }


  return (
    <div id="bod" className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Detalle Petróleo</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div>

                        <div> 
                    <div className="form-group">
                        <label htmlFor="dia">Fecha 1</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha1"
                            name="fecha1"
                            onChange={handleChange}
                            onBlur={f1}
                            required/>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="dia">Fecha 2</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha2"
                            name="fecha2"
                            onChange={handleChange}
                            onBlur={f2}
                            required/>
                        </div>
                      </div>

                      </div>

                      

                      

                      

                    </div>
                  </div>

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Consultar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Tabla Petróleo</h3>
                </div>
                <div className="card-body">

                <div className="card-body table-responsive p-0" style={{height: 300,overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Fecha</th>
                <th>Patente</th>
                <th>Litros</th>
                <th>Vale</th>
                <th>Conductor</th>
                <th>Valor</th>
                <th>Odometro</th>
                
            </tr>
          </thead>
          <tbody>
          {tabpe.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}> 
               {/* Litros */}
               <span style={{display:'none'}}>{a += parseInt(cli.litros)}</span>
               
               
               
               
                <td>{moment(cli.fecha).format('DD/MM/yyyy')}</td>
                <td>{cli.patente}</td>
                <td>{cli.litros}</td>
                <td>{cli.vale}</td>
                <td>{cli.conductor}</td>
                <td>{cli.valor}</td>
                <td>{cli.odometro}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>{a}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                
                
            </tr>
          </thead>
        </table>
      </div>
                    
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>














    </div>
  )
}
