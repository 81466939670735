import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'



export default function Produccion() {


// Para enviar a otras paginas
const history = useHistory();

// Variables de los combobox
const [ubi, setUbi] = useState([]); // ubicaciones
const [des, setDes] = useState([]); // destinos


// Inputs del formulario
const [inputs, setInputs] = useState({});


// Modal de éxito
const swal_bien = () => {
  withReactContent(Swal).fire({
    title: "Bien",
    text: "Ingresado Con Éxito",
    icon: "success",
  });
};

// Se llaman las Funciones
useEffect(() => {
  //Carga todo
  getAll();
}, []);

// Registra las entradas de los inputs
const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs((values) => ({ ...values, [name]: value }));
  setInputs((values) => ({ ...values, ["digitador"]: que2 }));
  setInputs((values) => ({ ...values, ["codigo"]: "pdf" }));
};
// Envia el formulario
const handleSubmit = (event) => {
  event.preventDefault();
  axios
    .post(rialURL + "sanvi/api/user/save", inputs)
    .then(function (response) {
    //   console.log(response.data);
    //   swal_bien();
    //   document.getElementById("ubicacion").value = "0";
    //   document.getElementById("destino").value = "0";
    //   document.getElementById("distancia").value = "";
    //   document.getElementById("valor").value = "";
      
    //   setInputs(null); // limpia lo que se envia 
      let fecha1=document.getElementById("fecha1").value;
      let fecha2=document.getElementById("fecha1").value;
      let cond=document.getElementById("cond").value;
      window.location.replace(rialURL + 'sanvi/api/user/save?cod=pdf&fecha1='+fecha1+'&fecha2='+fecha2+'&cond/pat='+cond);
    });

  //console.log(inputs);
};

// Funcion get para todos los comboBox
function getAll() {

 // Get Ubicaciones
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getUbi")
      .then(function (response) {
       // console.log(response.data);
        setUbi(response.data);
      });

       // Get Destinos
    axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getDes")
    .then(function (response) {
      //console.log(response.data);
      setDes(response.data);
    });

}

  return (
    <div id="bod" className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Porduccion Conductores</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div>

                        <div> 
                    <div className="form-group">
                        <label htmlFor="dia">Fecha 1</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha1"
                            name="fecha1"
                            onChange={handleChange}
                            required/>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="dia">Fecha 2</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha2"
                            name="fecha2"
                            onChange={handleChange}
                            required/>
                        </div>
                      </div>

                      </div>

                    <div className="form-group">
                        <label htmlFor="nombre">Ubicacion</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="cond"
                          id="cond"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Ubicacion</option>
                          {ubi.map((u, key) => (
                            <option key={key} value={u.ubicacion}>
                              {u.ubicacion}
                            </option>
                          ))}
                          <option value="Zurita Cristian">Zurita Cristian</option>
                        </select>
                      </div>
                      

                      

                      

                    </div>
                  </div>

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Descargar PDF
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
