import React from 'react'
import { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
} from "chart.js";
import axios from "axios";
import {rialURL, que2} from '../App.js'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);
export default function Todo() {
const [todo2, setTodo2] = useState([]);  
const [barra, setBarra] = useState([]);  
var o_todo2 = 0;
var g_todo2 = 0;
var por_todo2 = 0;
var prod_todo2 = 0;
var sal_todo2 = 0;
const hoy = new Date();

const liOptions = {
  responsive: true
  
};

//Grafico Rendimiento por dia
var liDias = [];
barra.map((cli, key) => liDias.push(cli.patente));
var liData = [];
barra.map((cli, key) => liData.push(cli.produccion));
var barr = {
  labels: liDias,
  datasets:[
      {
          label: "Producción",
          data: liData,
          backgroundColor:["rgba(0,255,0, 0.5)"],
          borderColor:["rgba(0,255,0, 1)"],
          borderWidth: 1,
      },
      
  ]
}


// Se llaman las Funciones
useEffect(() => {
  
  
    // Carga todo
     getAll();
  }, []);
  

function getAll (){

  let pickm_gas = document.getElementById("pickm_gas").value;
  pickm_gas = moment(pickm_gas).format('MM');

    // Get Tabla Resumen
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_resu2&month="+pickm_gas)
   .then(function (response) {
     console.log(response.data);
     setTodo2(response.data);
     
   });

   let pickm_gas2 = document.getElementById("pickm_gas2").value;
  pickm_gas = moment(pickm_gas).format('MM');

    // Get Tabla Resumen
   axios
   .get(rialURL + "sanvi/api/guias/ver?cod=get_bar_resu&month="+pickm_gas2)
   .then(function (response) {
     console.log(response.data);
     setBarra(response.data);
     
   });
}

const diario_gas = (ele) => {
  
  let pickm_gas = ele.target.value;
  pickm_gas = moment(pickm_gas).format('MM');

  // Get Tabla Resumen
  axios
  .get(rialURL + "sanvi/api/guias/ver?cod=get_tab_resu2&month="+pickm_gas)
  .then(function (response) {
    console.log(response.data);
    setTodo2(response.data);
    
  });

}

const diario_gas2 = (ele) => {
  
  let pickm_gas = ele.target.value;
  pickm_gas = moment(pickm_gas).format('MM');

  // Get Tabla Resumen
  axios
  .get(rialURL + "sanvi/api/guias/ver?cod=get_bar_resu&month="+pickm_gas)
  .then(function (response) {
    console.log(response.data);
    setBarra(response.data);
    
  });

}

  return (
    <div id="bod" className="content-wrapper">
      <section className="content arriba">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title centrito">Resumen todas las Patentes</h3> 
                  <input style={{float:"right"}} className='form-control4' defaultValue={moment(hoy).format('yyyy-MM')} type="month" onChange={diario_gas} id="pickm_gas" name="pickm_gas"/>
                </div>
                <div className="card-body">
                
          <div className="card-body table-responsive p-0" style={{height: "300px",overflow: "auto"}}>
        <table className="table table-head-fixed text-nowrap">
          <thead>
            <tr>
                <th>Patente</th>
                <th>Conductor</th>
                <th>Gasto Petróleo</th>
                <th>Gastos</th>
                <th>%</th>
                <th>Producción</th>
                <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
          {todo2.map((cli, key) =>
            <tr className="tede" id ="kk" key={key}>
            
              
               {/* Petro */}
            <span style={{display:'none'}}>{o_todo2 += parseInt(cli.petroleo) || 0}</span> 
            {/* Gastos */}
            <span style={{display:'none'}}>{g_todo2 += parseInt(cli.gasto) || 0}</span> 
            {/* % */}
            <span style={{display:'none'}}>{por_todo2 += parseInt(cli.porcentaje) || 0}</span> 
            {/* Produccion */}
            <span style={{display:'none'}}>{prod_todo2 += parseInt(cli.produccion) || 0}</span> 
            {/* Saldo */}
            <span style={{display:'none'}}>{sal_todo2 += parseInt(cli.saldo) || 0}</span> 
 
               
                <td>{cli.patente}</td>
                <td>{cli.conductor}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.petroleo)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.gasto)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.porcentaje)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.produccion)}</td>
                <td>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(cli.saldo)}</td>
                
              </tr>)}
          
            
          </tbody>
          <thead>
            <tr>
                <th>Totales:</th>
                <th></th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(o_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(g_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(por_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(prod_todo2)}</th>
                <th>${new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(sal_todo2)}</th>
            </tr>
          </thead>
        </table>
      </div>
                
          
                    
                </div> 
                {/* final primer cardbody */}


                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 style={{textWrap:'text-wrap: balance'}} className="card-title centrito">Produccion Por Patente</h3>
                  <input style={{float:"right"}} className='form-control4' defaultValue={moment(hoy).format('yyyy-MM')} type="month" onChange={diario_gas2} id="pickm_gas2" name="pickm_gas2"/>
                </div>
                <div className="card-body chart_container">
                <Bar options={liOptions} data={barr} />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
