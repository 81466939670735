import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'



export default function Gastos() {

// Para enviar a otras paginas
const history = useHistory();

// Variables de los combobox
const [pat, setPat] = useState([]); // patentes
const [con, setCon] = useState([]); // conductores


// Inputs del formulario
const [inputs, setInputs] = useState({});


// Modal de éxito
const swal_bien = () => {
  withReactContent(Swal).fire({
    title: "Bien",
    text: "Ingresado Con Éxito",
    icon: "success",
  });
};

// Se llaman las Funciones
useEffect(() => {
  //Carga todo
  getAll();
}, []);

// Registra las entradas de los inputs
const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs((values) => ({ ...values, [name]: value }));
  setInputs((values) => ({ ...values, ["digitador"]: que2 }));
  setInputs((values) => ({ ...values, ["codigo"]: "ingGas" }));
};
// Envia el formulario
const handleSubmit = (event) => {
  event.preventDefault();
  axios
    .post(rialURL + "sanvi/api/user/save", inputs)
    .then(function (response) {
      console.log(response.data);
      swal_bien();
      document.getElementById("fecha").value = "";
      document.getElementById("patente").value = "0";
      document.getElementById("valor").value = "";
      document.getElementById("detalle").value = "";
      document.getElementById("vale").value = "";
      document.getElementById("conductor").value = "0";
      document.getElementById("digitador").value = "";
      setInputs(null); // limpia lo que se envia 
      //history.push('/Ver');
    });

  //console.log(inputs);
};

// Funcion get para todos los comboBox
function getAll() {
  // Get Patentes
  axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getPat")
    .then(function (response) {
      //console.log(response.data);
      setPat(response.data);
    });
  // Get Conductores
  axios
    .get(rialURL + "sanvi/api/guias/ver?cod=getCon")
    .then(function (response) {
      //console.log(response.data);
      setCon(response.data);
    });

}

  return (
    <div id="bod" className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Ingreso Gastos</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div>
                      <div className="form-group">
                        <label htmlFor="dia">Fecha</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha"
                            name="fecha"
                            onChange={handleChange}
                            required/>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Patente</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="patente"
                          id="patente"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Patente</option>
                          {pat.map((u, key) => (
                            <option key={key} value={u.patente}>
                              {u.patente}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Valor Gasto</label>
                        <input
                          type="tel"
                          className="form-control inputi"
                          id="valor"
                          name="valor"
                          onChange={handleChange}
                          placeholder="Ingrese valor"
                          required/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Detalle Gasto</label>
                        <textarea 
                          className="form-control inputi"
                          id="detalle"
                          name="detalle"
                          onChange={handleChange}
                          placeholder="Ingrese detalle"
                          required></textarea>

                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">N° de Vale/Boleta</label>
                        <input
                          type="tel"
                          className="form-control inputi"
                          id="vale"
                          name="vale"
                          onChange={handleChange}
                          placeholder="Ingrese Numero"
                          required/>
                      </div>


                      <div className="form-group">
                        <label htmlFor="nombre">Conductor</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="conductor"
                          id="conductor"
                          onChange={handleChange}
                          required >
                          <option value="0">Seleccione Conductor</option>
                          {con.map((u, key) => (
                            <option key={key} value={u.conductor}>
                              {u.conductor}
                            </option>
                          ))}
                        </select>
                      </div>



                      <div className="form-group">
                        <label htmlFor="nombre">Digitador</label>
                        <input
                          type="text"
                          className="form-control inputi"
                          id="digitador"
                          name="digitador"
                          placeholder="ingrese Nombre"
                          defaultValue={que2}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Ingresar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
