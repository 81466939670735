import React from 'react'
import { useState,useEffect } from "react"; 
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rialURL, que2} from '../App.js'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Subdes() {
    const [inputs,setInputs] = useState({});
    const [des, setDes] = useState([]); // destinos

    // Modal de éxito
const swal_bien = () => {
    withReactContent(Swal).fire({
      title: "Bien",
      text: "Ingresado Con Éxito",
      icon: "success",
    });
  };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        setInputs((values) => ({ ...values, ["digitador"]: que2 }));
        setInputs(values => ({...values, ['codigo']: 'ingSubDes'}));
      }
    
      const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(rialURL+'sanvi/api/user/save', inputs).then(function(response){
          console.log(response.data);
          swal_bien();
          document.getElementById("destino").value = "0";
          document.getElementById("subdestino").value = null;
          setInputs(null); // limpia lo que se envia 
  
          //history.push('/Ver');
      });
    
        //console.log(inputs);
      }

      // Se llaman las Funciones
useEffect(() => {
    //Carga todo
    getAll();
  }, []);

// Funcion get para todos los comboBox
function getAll() {
   
          // Get Destinos
       axios
       .get(rialURL + "sanvi/api/guias/ver?cod=getDes")
       .then(function (response) {
         //console.log(response.data);
         setDes(response.data);
       });
   
       
   
   }



  return (
    <div id='bod' className="content-wrapper">
    <section className="content-header">
      
    </section>

    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Crear SubDestinos</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="card-body">
                <div className="form-group">
                        <label htmlFor="nombre">Destino</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="destino"
                          id="destino"
                          onChange={handleChange}
                          required>
                          <option value="-">Seleccione Destino</option>
                          {des.map((u, key) => (
                            <option key={key} value={u.destino}>
                              {u.destino}
                            </option>
                          ))}
                        </select>
                      </div>
                  <div className="form-group">
                    <label htmlFor="nombre">Sub-Destino</label>
                    <input
                      type="text"
                      className="form-control"
                      id="subdestino"
                      name="subdestino"
                      onChange={handleChange}
                      placeholder="ingrese Subdestino"
                      required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Digitador</label>
                    <input
                      type="text"
                      className="form-control"
                      id="digitador"
                      name="digitador"
                      defaultValue={que2}
                      placeholder="Tema"
                    readOnly/>
                  </div>
                  
                </div>

                <div className="card-footer">
                  <button 
                  id="sos" 
                  className="btn btn-primary">
                    Ingresar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
