import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import {rialURL, que2} from '../App.js'
import { parseJSON } from "date-fns";


export default function Content() {
  // Para enviar a otras paginas
  const history = useHistory();

  // Variables de los combobox
  const [ubi, setUbi] = useState([]); // ubicaciones
  const [pat, setPat] = useState([]); // patentes
  const [mat, setMat] = useState([]); // materiales
  const [con, setCon] = useState([]); // conductores
  const [des, setDes] = useState([]); // destinos

  // Inputs del formulario
  const [inputs, setInputs] = useState({});


  // Modal de éxito
  const swal_bien = () => {
    withReactContent(Swal).fire({
      title: "Bien",
      text: "Ingresado Con Éxito",
      icon: "success",
    });
  };

  // Modal de error
  const swal_error = () => {
    withReactContent(Swal).fire({
      title: "Error",
      text: "Error al Ingresar, N° de Doumento ya ingresado",
      icon: "error",
    });
  };

  // Se llaman las Funciones
  useEffect(() => {
    //Carga todo
    getAll();
  }, []);

  // Registra las entradas de los inputs
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setInputs((values) => ({ ...values, ["digitador"]: que2 }));
    setInputs((values) => ({ ...values, ["codigo"]: "ingDoc" }));
  };
  // Envia el formulario
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(rialURL + "sanvi/api/user/save", inputs)
      .then(function (response) {
        console.log(response.data);
        if(String(response.data).includes('Duplicate entry')) {
          swal_error();
        }else{
          swal_bien();

          document.getElementById("fecha").value = ""; 
          document.getElementById("guia").value = ""; 
          document.getElementById("capacidad").value = "0";
          document.getElementById("ubicacion").value = "0";
          document.getElementById("patente").value = "0"; 
          document.getElementById("documento").value = "0";
          document.getElementById("material").value = "0";
          // document.getElementById("cantidad").value = "";
          document.getElementById("destino").value = "0";
          document.getElementById("conductor").value = "0";
          setInputs(null); // limpia lo que se envia   
        
        
        }
        //history.push('/Ver');
      }) .catch(error => {
        swal_error();
     });

    //console.log(inputs);
  };

  // Funcion get para todos los comboBox
  function getAll() {
    // Get Ubicaciones
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getUbi")
      .then(function (response) {
       // console.log(response.data);
        setUbi(response.data);
      });
    // Get Patentes
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getPat")
      .then(function (response) {
        //console.log(response.data);
        setPat(response.data);
      });
    // Get Materiales
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getMat")
      .then(function (response) {
        //console.log(response.data);
        setMat(response.data);
      });
    // Get Conductores
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getCon")
      .then(function (response) {
        //console.log(response.data);
        setCon(response.data);
      });
    // Get Destinos
    axios
      .get(rialURL + "sanvi/api/guias/ver?cod=getDes")
      .then(function (response) {
        //console.log(response.data);
        setDes(response.data);
      });
  }

  return (
    <div id="bod" className="content-wrapper">
      {/*<section  className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ingresar</h1>
            </div>
             <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Ingresar</a>
                </li>
                <li className="breadcrumb-item active">General Form</li>
              </ol>
            </div> 
          </div>
        </div>
      </section>*/}

      <section className="content ">
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-md-6 ar2">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Ingreso de Guias/Vales</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div>
                      <div className="form-group">
                        <label htmlFor="dia">Fecha</label>
                        <div className="input-group">
                          <input
                            type="date"
                            className="form-control inputi"
                            id="fecha"
                            name="fecha"
                            onChange={handleChange}
                          required/>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">N° de Guia/Vale</label>
                        <input
                          type="tel"
                          className="form-control inputi"
                          id="guia"
                          name="guia"
                          onChange={handleChange}
                          placeholder="Ingrese Numero"
                          required/>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Capacidad</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="capacidad"
                          id="capacidad"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione capacidad</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Ubicacion</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="ubicacion"
                          id="ubicacion"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Ubicacion</option>
                          {ubi.map((u, key) => (
                            <option key={key} value={u.ubicacion}>
                              {u.ubicacion}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Patente</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="patente"
                          id="patente"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Patente</option>
                          {pat.map((u, key) => (
                            <option key={key} value={u.patente}>
                              {u.patente}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <div className="form-group">
                        <label htmlFor="nombre">Tipo de Documento</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="documento"
                          id="documento"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Documento</option>
                          <option value="Guia">Guia</option>
                          <option value="Vale">Vale</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Material</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="material"
                          id="material"
                          onChange={handleChange}
                          required >
                          <option value="0">Seleccione Material</option>
                          {mat.map((u, key) => (
                            <option key={key} value={u.material}>
                              {u.material}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* <div className="form-group">
                        <label htmlFor="nombre">Cantidad</label>
                        <input
                          type="tel"
                          className="form-control inputi"
                          id="cantidad"
                          name="cantidad"
                          onChange={handleChange}
                          placeholder="Ingrese Cantidad"
                          required/>
                      </div> */}

                      <div className="form-group">
                        <label htmlFor="nombre">Destino</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="destino"
                          id="destino"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Destino</option>
                          {des.map((u, key) => (
                            <option key={key} value={u.destino}>
                              {u.destino}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Conductor</label>
                        <select
                          class="form-control selc inputi"
                          defaultValue="0"
                          name="conductor"
                          id="conductor"
                          onChange={handleChange}
                          required>
                          <option value="0">Seleccione Conductor</option>
                          {con.map((u, key) => (
                            <option key={key} value={u.conductor}>
                              {u.conductor}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="nombre">Digitador</label>
                        <input
                          type="text"
                          className="form-control inputi"
                          id="digitador"
                          name="digitador"
                          placeholder="ingrese Nombre"
                          defaultValue={que2}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button id="sos" className="btn btn-primary">
                      Ingresar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
