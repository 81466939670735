import React from 'react'
import { useState } from "react"; 
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rialURL} from '../App.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function Login() {
 // localStorage.setItem('log', JSON.stringify('inactivo'))
  const history = useHistory();
  const showSwal = () => {
    withReactContent(Swal).fire({
      
      title: "Error",
      text: "Usuario o Contraseña incorrectos",
      icon: "error"
      
    })
  } 



  const [user,setUser] = useState("");
  const [pass,setPass] = useState("");
  const [inputs,setInputs] = useState({}); // DA VALOR POR CADA INPUT
  

  const handleChange = (event) => { //registra la informacion de los Inputs
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setInputs(values => ({...values, ['codigo']: 'login'}));
    setInputs(values => ({...values, ['cod']: 'login'}));
  }

  const handleSubmit = (event) => { //envia la info de los inputs
    event.preventDefault();


    //http://localhost:80/api/user/save
    //https://appc.codecland.com/api/user/save
    axios.post(rialURL+'sanvi/api/login', inputs).then(function(response){
      console.log(response.data);


      if(response.data.status == 1){
        //response.data[0]['n_completo'] Puedes sacar la información cuando se consulta la base de datos. 
        
        localStorage.setItem('log', JSON.stringify('activo')) //Guarda la variable en forma local. El estado de la sesión sí es activo o inactivo. 
        localStorage.setItem('us', JSON.stringify(response.data.us))
       
        history.push("/inicio");
        window.location.reload(); //Esto recarga en la página para que el jackscript pueda funcionar si esta línea código no está, no funciona el código javascript. 
      }else{
        showSwal()
      }
      
  });

    //console.log(inputs);
  }


















  return (
    
    <div id="bod" className='hold-transition login-page'>
      
      <div className="login-box">
  <div className="card card-outline card-primary">
    <div className="card-header text-center">
      <a href="#" className="h1"> <b>Transportes San </b>Vicente</a>
    </div>
    <div className="card-body">
        <br></br>
      <hr></hr>
      <form onSubmit={handleSubmit}>
        <div className="input-group mb-3">
          <input type="text" id="user" name="user" onChange={handleChange} className="form-control" placeholder="Usuario" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="password" id="pass" name="pass" onChange={handleChange} className="form-control" placeholder="Contraseña" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            
          </div>
          <div className="col-4">
            <button type="submit" className="btn btn-primary btn-block"><i class="fa-solid fa-right-to-bracket"></i></button>
          </div>
        </div>
      </form>
      
      
    </div>
  </div>
</div>

    </div>
  )
  
}
